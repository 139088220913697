import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, CurrentSweepstakes, CurrentPromotions, DisplayContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { jurisdiction } from '../promotionConfig';
import PointsForDrawingsSection from '../Components/PointsForDrawings/PointsForDrawings';

const Promotions = ({ user, config, sweep, promotion, cmsSourceFirebase, actions, match, pointsForDrawings }) => {
    const telescriptCurrentSweepstakes = getCMSObject('data.components.teleScripts.currentPromotions.jsonBlock');
    const telescriptCurrentPromotions = getCMSObject('data.components.teleScripts.myRewardsHighlights.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <CurrentSweepstakes
                {...{ sweep, cmsSourceFirebase, actions }}
                telescript={telescriptCurrentSweepstakes}
                options={{ hasCollapser: false, variant: 'theme-primary' }}
            />
            <DisplayContent isVisible={config?.web?.features?.pointsForDrawings}>
                <section id='points-for-drawings'>
                    <PointsForDrawingsSection {...{ actions, pointsForDrawings }} />
                </section>
            </DisplayContent>
            <CurrentPromotions
                {...{ user, config, promotion, cmsSourceFirebase, actions }}
                telescript={telescriptCurrentPromotions}
                mode='show-limited'
                options={{
                    hasCollapser: false,
                    variant: 'theme-primary',
                    jurisdiction: jurisdiction,
                }}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
