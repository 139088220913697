import React from 'react';
import { connect } from 'react-redux';
import { UpdatePassword, getCMSObject } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const UpdatePasswordPage = ({ user, config, loading, cmsSourceFirebase, actions, match }) => {
    const updatePasswordTelescript = getCMSObject('data.components.teleScripts.UpdatePassword.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <UpdatePassword
                user={user}
                loading={loading}
                actions={actions}
                telescript={updatePasswordTelescript}
                options={
                    config?.site?.components?.updatePassword ?? {
                        variant: 'theme-primary',
                        hasCollapser: false,
                        showByDefault: true,
                        hash: 'update-password',
                        minimumPasswordLength: 10,
                    }
                }
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage);
