import { getCMSObject } from 'sg-ui-components';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../../Store';
import React from 'react';
import AppStoreColumn from './AppStoreColumn';

const DisclaimerTemplate = () => {
    const footerColumns = getCMSObject('data.footerDisclaimers');
    const today = new Date();
    return (
        <div className='footer-disclaimer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-md-3' dangerouslySetInnerHTML={{ __html: footerColumns?.footerTextColumn1?.contentHTML ?? '' }} />
                    <div className='col-md-6' dangerouslySetInnerHTML={{ __html: footerColumns?.footerTextColumn2?.contentHTML ?? '' }} />
                    <div className='col-md-2 col-md-2 col-md-offset-1'>
                        <AppStoreColumn/>
                    </div>
                </div>
                <p className='copyright'>&copy; {today.getFullYear()}. Delaware State Lottery. All rights reserved. </p>
            </div>
        </div>
    );
};

export const FooterDisclaimers = connect(mapStateToProps, mapDispatchToProps)(DisclaimerTemplate);
