import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import './optins.scss';
import {
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
    isHash,
    TeleScript,
    getCMSObject,
    ErrorMessage,
    SelectionWidget,
} from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**********************************************************************
 * Component:  RegistrationOptinForm
 * Purpose:   Used to create the notification selection chckboxes that
 *            are displayed on the Registration page 1.   This grab all
 *            optins available and filters out the ones in the
 *            "registration" category and displays that.
 *
 *            The parent (calling) class defines the callback to
 *            subscribe/unsubcribe as part of the registration
 *            payload.  So does NOT use the subscribe/unsubscribe APIs.
 *
 * Props: -   parentCallback -  callback returned to the parent (registration)
 *
 * APIs used: mrbEnsemble.getAllOptions
 */
const RegistrationOptinForm = ({ actions, optins, optinCategory = 'registration', parentCallback = '' }) => {
    const [loaded, setLoaded] = useState(false);
    const [registrationOptins, setRegistrationOptins] = useState([]);
    const [registrationCheckedState, setRegistrationCheckedState] = useState(new Map());

    //***********************************************************************************
    // Gets all the optins available (without any player data attached).
    // this could be a large list and will need to be filtered down by category
    const getAllOptins = async () => {
        await actions.optinsActions.getAllOptins();
    };

    //***********************************************************************************
    // Gets all optins when component loads.
    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            getAllOptins();
        }
    }, [loaded]); // end useEffect

    //***********************************************************************************
    // Iterates over the optins list and filters out only the ones in the registration
    // category, as this component should only be used on registration pages.
    //
    // Creates a MAP that keeps track of the checked status of the optin.
    useEffect(() => {
        const myOptins = optins.allOptins;
        if (myOptins) {
            // Filter the Optin List so it just contains the registration optin
            const regOptins = myOptins.filter((regOpton) => regOpton.context === optinCategory);

            setRegistrationOptins(regOptins);

            // Map checked values of by optinID.
            let checkedStateById = new Map();

            // using a map of maps here to keep track of the clicked state of the option box.
            // Remapping to confirm with the optin array data requested by backend in /player/register
            // for the front-end we will always assume the subscription type is email.
            regOptins.forEach((optin) => {
                checkedStateById.set(optin.id, {
                    checkedState: true,
                    optin: { type: optin.type, subscriptions: [{ type: 'email' }] },
                });
            });
            setRegistrationCheckedState(new Map(checkedStateById));
        }
    }, [optins]); // end useEffect

    //***********************************************************************************
    // Checked state for this widget managed internally since we won't change the
    // store state until post-registration.
    //
    const handleClick = async (id) => {
        if (registrationCheckedState.size > 0) {
            // Duplicate the Map, change it, and then reset it.
            let newCheckedState = new Map(registrationCheckedState);
            const isChecked = newCheckedState.get(id);
            isChecked.checkedState = !isChecked.checkedState;
            newCheckedState.set(id, isChecked);

            // Maps are a special beast with set state
            setRegistrationCheckedState(new Map(registrationCheckedState));

            // If a parent callback is set, the parent will handle the call to
            // subscribe/unsubscribe a notification.
            if (parentCallback === '') {
                if (newCheckedState.get(id)) {
                    await actions.optinsActions.optinSubscribe(id);
                }
            }
        }
    }; // end handleChange

    return (
        <div className='form-check my-3'>
            {registrationOptins
                ? registrationOptins.map((optin) => {
                      return (
                          <div key={optin.id}>
                              <input
                                  type='checkbox'
                                  id={optin.id}
                                  name={optin.type}
                                  className='form-check-input'
                                  value={optin.id.toString()}
                                  checked={registrationCheckedState.get(optin.id) ? registrationCheckedState.get(optin.id).checkedState : 'true'}
                                  onClick={() => handleClick(optin.id)}
                                  onChange={parentCallback != '' ? parentCallback(registrationCheckedState) : ''}
                              />
                              <label htmlFor='marketing' className='form-check-label'>
                                  {optin.tag}
                              </label>
                          </div>
                      );
                  })
                : ''}
        </div>
    );
}; // end RegistrationOptins

const RegistrationOptins = connect(mapStateToProps, mapDispatchToProps)(RegistrationOptinForm);

export { RegistrationOptins };

/*******************************************************************************************
 * Component: OptinForm
 * Purpose:   Used to create the notification selection widget/card that
 *            lists all available optins for the user that they can select
 *            categorized by context.  This was update for DE and uses a bulk
 *            update of optins rather than updating them one by one, see Missouri code
 *            for an example of the "old" way of doing these.
 *
 * Props: - actions - store actions (apis)
 *          user - reference to the user store
 *          optins - reference to the optins/notifiction store
 *          heading - optional heading for the content box.
 *
 * APIs used:   mrbEnsemble.getOptinsByCategory - all optins with player data sorted
 *                                                 in a key/value pair grouped by context.
 *              mrbEnsemble.updateOptins -  updates the user's selections and subscribes /
 *                                          unsubscribes the notifications in bulk.
 *
 */
const OptinsTemplate = ({ actions, user, optins, heading = '' }) => {
    const [loaded, setLoaded] = useState(false);
    const [successfulUpdate, setSuccessfulUpdate] = useState(false);
    const [subscribedOptins, setSubscribedOptins] = useState([]);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const myNotificationsTelescript = getCMSObject('data.components.teleScripts.myNotifications.jsonBlock');
    const hash = 'my-notifications';

    //***********************************************************************************
    // Get all the Optins from the API (with player data)
    const getPlayerOptins = async () => {
        // Get the Optins from the Optin Store.
        await actions.optinsActions.getOptinsByCategory();
    }; // end getAllOptins

    //***********************************************************************************
    // Get all the Optins data on load or when the checkedState has changed (and thus when
    // a player has subscribed/unsubscribed from that optin.
    //
    const clearAction = async (actionName) => {
        if (user.player?.actions?.length > 0 && user.player.actions.includes(actionName)) {
            await actions.userActions.actionComplete({ action_name: actionName });
        }
    };

    useEffect(() => {
        let currentlySubscribedOptins = [];
        if (!loaded) {
            setLoaded(true);
            clearAction('review-optins'); //? we need to clear this action on pageload
            getPlayerOptins();
        }

        if (updateInProgress && !optins.errors) {
            setUpdateInProgress(false);
            setSuccessfulUpdate(true);
        }

        optins.optinsByCategory &&
            Object.keys(optins.optinsByCategory).forEach((key) => {
                optins?.optinsByCategory[key].forEach((optin) => {
                    let subscribed = optin?.subscription?.find(({ channel }) => channel === 'email');
                    if (subscribed) {
                        if (optin?.subscription?.[0]?.jackpot) {
                            const subscribedOptin = {
                                type: optin.type,
                                channel: { type: 'email' },
                                filter: { jackpot: optin?.subscription?.[0]?.jackpot },
                            };
                            currentlySubscribedOptins.push(subscribedOptin);
                        } else {
                            const subscribedOptin = {
                                type: optin.type,
                                channel: { type: 'email' },
                                filter: {},
                            };
                            currentlySubscribedOptins.push(subscribedOptin);
                        }
                    }
                });
            });
        setSubscribedOptins(currentlySubscribedOptins);
    }, [loaded, optins]); // end useEffect

    const unsubscribe = async (optinToUnsubscribe) => {
        let currentSubscriptions = [...subscribedOptins];
        let index = currentSubscriptions.findIndex((optin) => optin.type === optinToUnsubscribe.type);
        if (index != -1) {
            currentSubscriptions.splice(index, 1);
            setSubscribedOptins(currentSubscriptions);
        }
    };
    //***********************************************************************************
    // Handler for when a subscription is clicked -  will add or remove the optin from
    // the local array of subscribedOptins
    const handleClick = async (affectedOptin, event) => {
        setSuccessfulUpdate(false);
        const selectedValue = event.currentTarget.value;
        const isChecked = event.currentTarget.checked;
        let currentlySubscribed = [...subscribedOptins];

        if (isChecked) {
            if (Object.keys(affectedOptin.filters ?? []).length) {
                if (currentlySubscribed.find((obj) => obj.type === affectedOptin.type)) {
                    var foundIndex = currentlySubscribed.findIndex((x) => x.type === affectedOptin.type);
                    currentlySubscribed[foundIndex] = {
                        type: affectedOptin.type,
                        channel: { type: 'email' },
                        filter: { jackpot: selectedValue },
                    };
                } else {
                    currentlySubscribed.push({
                        type: affectedOptin.type,
                        channel: { type: 'email' },
                        filter: { jackpot: selectedValue },
                    });
                }
            } else {
                currentlySubscribed.push({ type: affectedOptin.type, channel: { type: 'email' }, filter: {} });
            }

            setSubscribedOptins(currentlySubscribed);
        } else {
            unsubscribe(affectedOptin);
        }
    }; // end handleClick

    const saveOptins = async () => {
        const payloadData = {
            optins: subscribedOptins ?? {},
        };
        setUpdateInProgress(true);

        await actions.optinsActions.updateOptins(payloadData);
        setLoaded(false);
    };

    const OptinWithFilters = ({ optin }) => {
        let filterOptions = [];
        const [showFilters, setShowFilters] = useState(false);
        let checked = '';
        let filter = '';

        if (subscribedOptins.length) {
            let subscription = subscribedOptins.find(({ type }) => type === optin.type);
            checked = subscription?.type ?? '';
            filter = subscription?.filter?.jackpot ?? '';
        }

        optin?.filters &&
            Object.entries(optin.filters).forEach((filter) => {
                filterOptions.push({
                    label: filter[1],
                    value: filter[0],
                    name: optin.tag,
                });
            });

        // Toggle the child filters.
        const handleFilterControlClick = async (event, optinAffected) => {
            setShowFilters(!showFilters);
            const isChecked = event.currentTarget.checked;
            let currentlySubscribed = [...subscribedOptins];

            if (isChecked) {
                if (Object.keys(optinAffected.filters ?? []).length) {
                    currentlySubscribed.push({
                        type: optinAffected.type,
                        channel: { type: 'email' },
                        filter: { jackpot: Object.values(optinAffected.filters)[0] },
                    });
                } else {
                    currentlySubscribed.push({ type: optinAffected.type, channel: { type: 'email' }, filter: {} });
                }

                setSubscribedOptins(currentlySubscribed);
            }

            if (!event.currentTarget.checked) {
                unsubscribe(optinAffected);
            }
        };

        return (
            // Look for the control
            <div key={optin.id} className='form-group notification-control'>
                <div className='form-check'>
                    <input
                        type='checkbox'
                        id={optin.id}
                        name={optin.type}
                        className='form-check-input'
                        value={optin.id.toString()}
                        defaultChecked={checked}
                        onClick={(event) => handleFilterControlClick(event, optin)}
                    />
                    <label className='form-check-label' htmlFor={optin.id}>
                        {optin.extra?.control_text ?? optin.tag}
                    </label>
                </div>

                {showFilters || checked ? (
                    <div id={`SelectionWidget-${optin.id}`} className='controlled-filters'>
                        <SelectionWidget
                            key={optin.id}
                            options={filterOptions}
                            changeCallback={(event) => handleClick(optin, event)}
                            className='nested-notification-filters'
                            defaultValue={filter.toString() || filterOptions[0].value}
                        />
                    </div>
                ) : null}
            </div>
        );
    };

    const OptinWithoutFilters = ({ optin }) => {
        const selectionWidgetOptions = [
            {
                label: optin.tag,
                value: optin.id.toString(),
            },
        ];
        let checked = '';

        if (subscribedOptins.length) {
            let subscription = subscribedOptins.find(({ type }) => type === optin.type);
            if (subscription) {
                checked = subscription.type ?? '';
            }
        }

        return (
            <SelectionWidget
                key={optin.id}
                options={selectionWidgetOptions}
                changeCallback={(event) => handleClick(optin, event)}
                className='form-check'
                defaultValue={checked.toString()}
            />
        );
    };

    return (
        <ContentBox variant='theme-primary' id={hash} show={isHash(hash) ? 'true' : 'false'}>
            <ContentBoxHead>
                {heading}
                <ContentCollapser />
            </ContentBoxHead>
            <ContentBoxBody>
                {updateInProgress ? (
                    <React.Fragment key='loading-indicator-spection'>
                        <div className='d-flex justify-content-center bg-white'>
                            <div className='loading-indicator' role='status'>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <>
                        <ErrorMessage code={optins?.error ?? ''} collection='data.messages.errorMessages.jsonBlock' />
                        {successfulUpdate ? (
                            <Alert variant='success'>
                                <FontAwesomeIcon icon='fa-solid fa-circle-check' />
                                <span className='alert-text'>
                                    <TeleScript line={myNotificationsTelescript?.successfulSaveMessage}>Notifications have been updated.</TeleScript>
                                </span>
                            </Alert>
                        ) : null}
                        <div className='form-step'>
                            <div className='inner-step'>
                                <TeleScript line={myNotificationsTelescript?.heading}>
                                    <h2>My Notification Options</h2>
                                </TeleScript>
                                <TeleScript line={myNotificationsTelescript?.introDirections}>
                                    <p>Select notification options below to stay up to date.</p>
                                </TeleScript>
                                {optins.optinsByCategory &&
                                    Object.keys(optins.optinsByCategory).map((key, i) => {
                                        return (
                                            <div key={i} className='notification-type'>
                                                <h3 key={i}>
                                                    {optins?.optinsByCategory[key][0]?.extra?.context_heading ?? optins?.optinsByCategory[key].context}
                                                </h3>
                                                {optins?.optinsByCategory[key].map((optin) => {
                                                    if (optin.filters && Object.keys(optin.filters).length) {
                                                        return <OptinWithFilters key={optin.id} optin={optin} />;
                                                    } else {
                                                        return <OptinWithoutFilters key={optin.id} optin={optin} />;
                                                    } // end else if (option.filters.length > 0)
                                                })}
                                            </div>
                                        );
                                    })}
                                <TeleScript line={myNotificationsTelescript?.saveInstructions}>
                                    <p className='save-instructions'>Click &ldquo;Update Preferences&rdquo; button when you have made your changes.</p>
                                </TeleScript>
                                <div className='text-center'>
                                    <button type='button' className='save-optins-btn' onClick={saveOptins}>
                                        <TeleScript line={myNotificationsTelescript?.saveButtonText}>Update Preferences</TeleScript>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </ContentBoxBody>
        </ContentBox>
    );
}; // end OptinAccordion

const OptinAccordian = connect(mapStateToProps, mapDispatchToProps)(OptinsTemplate);

export { OptinAccordian };
