import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { RegistrationSteps } from '../Components/RegisterForm/registration_steps';

const Register = ({ user, config, cmsSourceFirebase, actions, match }) => {

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <RegistrationSteps />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
