import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import { NavHashLink } from 'react-router-hash-link';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

library.add(faMagnifyingGlass, faCalendar);

const Menu = (props) => {
    const replaceUrlSigils = (urlString) => {
        const sigilDictionary = [
            {
                find: '{{LOTTERY_URI}}',
                replace: props.config.config.lottery_host,
            },
            {
                find: '{{STORE_URI}}',
                replace: props.config.config.store_uri,
            },
        ];

        sigilDictionary.forEach((dictEntry) => {
            urlString = urlString.replaceAll(dictEntry.find, dictEntry.replace);
        });

        return urlString;
    };

    const setLinkProps = (menuItemNode) => {
        let propsObj = {};

        if (menuItemNode.route) {
            propsObj = { ...propsObj, as: Link, to: menuItemNode.route };
        } else if (menuItemNode.webLinkUrl) {
            propsObj = {
                ...propsObj,
                as: 'a',
                href: replaceUrlSigils(menuItemNode.webLinkUrl),
                target: menuItemNode.webLinkTarget,
            };
        }

        return propsObj;
    };

    if (props.menu_json && props.menu_json.length) {
        return (
            <Nav as='ul' className={props.className}>
                {props.menu_json.map((node, key) => {
                    if (node.class === 'social') {
                        return (
                            <div className={`social-wrap ${key}`} key={`menu-${key}`}>
                                <Nav.Link {...setLinkProps(node)} role='button' className={node.class}>
                                    {node.icon && <i className={`icon ${node.icon}`} />}
                                    {node.label}
                                </Nav.Link>
                            </div>
                        );
                    } else if (!node.subMenuList) {
                        return (
                            <Nav.Item as='li' key={`menu-${key}`}>
                                <Nav.Link {...setLinkProps(node)} role='button' className={node.class}>
                                    {node.icon && <i className={`icon ${node.icon}`} />}
                                    {node.label}
                                </Nav.Link>
                            </Nav.Item>
                        );
                    } else if (node.subMenuList.length) {
                        return (
                            <Nav.Item as='li' className='has-dropdown' key={`menu-${key}`}>
                                <NavDropdown title={node.label} className='nav-submenu'>
                                    {node.subMenuList.map((subNode, key) => {
                                        return (
                                            <NavDropdown.Item
                                                {...setLinkProps(subNode)}
                                                role='button'
                                                key={`sub-menu-${key}`}
                                                className={node.class}
                                                as={NavHashLink}>
                                                {subNode.label}
                                            </NavDropdown.Item>
                                        );
                                    })}
                                </NavDropdown>
                            </Nav.Item>
                        );
                    } else {
                        return (
                            <li className={`nav-item ${node.class}`} key={`menu-${key}`}>
                                {node.icon && <i className={`icon ${node.icon}`} />}
                                {node.label}
                            </li>
                        );
                    }
                })}
            </Nav>
        );
    } else {
        return null;
    }
};

Menu.propTypes = {
    menu_json: PropTypes.array,
    className: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
