import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import PasswordExpired from '../Components/Login/PasswordExpired';

const PasswordExpiredPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <PasswordExpired />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiredPage);
