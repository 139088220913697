import { useEffect, forwardRef } from 'react';

//**************************************************************************************************
// clickOutsideComponentEvent
//
// Adds a "click outside" component event to the given reference passed in and then calls the
// passed in callback method.
//
// Notes:  Returns null, as its more of a helper component.
//
const ClickOutsideComponentEvent = forwardRef(({ callback }, ref) => {
    useEffect(() => {
        // handle the click outside of the reference and call the callback.
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
    return null;
});
ClickOutsideComponentEvent.displayName = 'ClickOutsideComponentEvent';
export default ClickOutsideComponentEvent;
