import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, TeleScript, isHash, TableMobile, TableDesktop, formatDate } from 'sg-ui-components';
import AllocatableEntriesForm from '../AllocatableEntries/AllocatableEntriesForm';
import Modal from 'react-bootstrap/Modal';
import './my-allocatable-entries.scss';

/**********************************************************************
 * Component: MyAllocatableEntries
 * Purpose:   Shows all the player's outstanding entries they can allocate
 *            for all sweepstakes.    Part of the My Activity page
 *
 * Props: - actions       -  actions table for api calls
 *          sweep         -  reference to the parent Sweepstake
 *          loading       -  reference to the loading store
 *          myAllocatableTelescript -  Telescript to allow for content editing via CMS
 *
 *
 * APIs used:  /sweepstake/entry/allocatable - get players allocatable entries
 *             /sweepstake/current -  gets list of current sweepstakes
 */
const MyAllocatableEntries = ({ actions, sweep, loading, telescript }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [hasEntries, setHasEntries] = useState(false);
    const hash = 'allocatable-entries';

    //***********************************************************************************
    // On load get the both the list of current sweeps and all player's allocatable
    // entries.
    //
    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                await actions.sweepActions.getPlayerAllocatableEntries();
                await actions.sweepActions.getSweeps();
                setPageLoaded(true);
            }
        }

        fetchData();

        return () => {
            setPageLoaded(false);
            setHasEntries(false);
        };
    }, []);

    useEffect(() => {
        if (sweep?.playerAllocatableEntries?.length) {
            setHasEntries(true);
        }
    }, [sweep]);

    const DetailsMobile = ({ entry }) => {
        const [show, setShow] = useState(false);

        const showModal = () => {
            setShow(true);
        };

        return (
            <div key={entry.id}>
                <Button variant='link' className='theme-btn-link p-0' onClick={() => showModal((entry = { entry }))}>
                    <TeleScript line={telescript?.buttonDetails}>Assign Now</TeleScript>
                </Button>
                <AllocatableModal show={show} setShow={setShow} entry={entry} />
            </div>
        );
    };

    const AllocatableModal = ({ show, setShow, entry }) => {
        const handleModalClose = () => {
            setShow(false);
        };

        return (
            <Modal
                key={entry.id}
                show={show}
                onHide={handleModalClose}
                backdrop='static'
                keyboard={false}
                size='lg'
                dialogClassName='allocatable-form-modal'
                contentClassName='allocatable-form-modal'>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>
                        <TeleScript line={telescript?.allocatableEntriesTitle}>Allocate Your Entries</TeleScript>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AllocatableEntriesForm
                        sweepId={entry.sweeps_id ?? ''}
                        promotionName={entry.sweepstake_name ?? ''}
                        numEntries={entry.quantity ?? ''}
                        entryId={entry.id ?? ''}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleModalClose}>
                        <TeleScript line={telescript?.buttonClose}>Close</TeleScript>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const entriesTable = (data) => {
        const entries = Array.isArray(data) ? data : [];

        const displayHeaders = {
            drawing: telescript?.tableDrawing ?? 'Second-Chance Promotion',
            allocateBy: telescript?.tableAllocateBy ?? 'Assign by*',
            entriesToAllocate: telescript?.tableEntriesToAllocate ?? 'Entries to Allocate',
            details: telescript?.tableDetails ?? 'Details',
        };

        const displayRows = [...entries].map((entry) => ({
            ...entry,
            fields: {
                drawing: entry?.sweepstake_name ?? 'N/A',
                allocateBy: formatDate?.dateTimeWithMeridiem(entry?.allocate_by),
                entriesToAllocate: entry?.quantity ?? 'N/A',
                details: <DetailsMobile key={entry?.id} entry={entry} />,
            },
        }));

        const displayOptions = {
            tableId: 'allocatable-entries-table',
            isLoaded: pageLoaded,
            messageNoRows: telescript?.messageNoRows ?? 'You currently have no second-chances entries to assign',
        };

        return (
            <>
                <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
            </>
        );
    };

    if (loading.actions['allocateEntries']) {
        return null;
    } else {
        return (
            <ContentBox variant='theme-primary' id={hash} show={isHash(hash) ? true : hasEntries}>
                <ContentBoxHead>
                    <TeleScript line={telescript?.allocatableEntriesTitle}>My Unassigned Entries</TeleScript>
                    <ContentCollapser />
                </ContentBoxHead>
                <ContentBoxBody>
                    <div className='row'>
                        <div className='col'>
                            {entriesTable(sweep?.playerAllocatableEntries)}
                            <div className='text-center'>
                                <small>
                                    <TeleScript line={telescript.footnote}>
                                        * All unassigned entries are entered into the default prize for that drawing.
                                    </TeleScript>
                                </small>
                            </div>
                        </div>
                    </div>
                </ContentBoxBody>
            </ContentBox>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAllocatableEntries);
