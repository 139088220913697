import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import EnterTicketsPage from '../Components/Enter/EnterTicketsPage';

const EnterPage = ({ user, config, cmsSourceFirebase, actions, match }) => {

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <EnterTicketsPage />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterPage);
