import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';
import { CantFindConfirmation } from './CantFindConfirmation';
import { ReadyToGo } from './ReadyToGo';
import { CMSContent, ContentBox, ContentBoxBody, ContentBoxHead, getCMSObject, TeleScript } from 'sg-ui-components';

/**********************************************************************
 * Component:  AwaitAccountConfirmation
 * Purpose:    Simple Component that tells user to wait for their
 *             account confirmation email.    The user has the option to
 *             resend the email if it hasn't come in.
 *
 * Props:       user -  user data store
 *              actions - store actions (apis)
 *              config -  front-end configuration object
 *              cmsSourceFirebase - CMS firebase store
 *
 */
const AwaitAccountConfirmation = ({ user, actions, config, cmsSourceFirebase }) => {
    const [seconds, setSeconds] = useState(0);
    const [timerActive, setTimerActive] = useState(true);
    const [timeoutHit, setTimeoutHit] = useState(false);
    const [accountConfirmed, setAccountConfirmed] = useState(false);
    const timeout = config.config.account_confirm_wait_seconds;
    const accountConfirmationTelescript = getCMSObject('data.components.teleScripts.accountConfirmation.jsonBlock');

    //***********************************************************************************
    // Request a resend of the confirmation email and reset timer to 0
    const resendConfirmationEmail = async (e) => {
        e.preventDefault();
        await actions.loadingActions.setLoading({ action: 'confirmResend' });
        await actions.userActions.updateSection({ section: 'resend-confirm-email', status: 'initial' });
        const payload = {
            registrar_id: user.player.registrar_id ?? '',
        };
        await actions.userActions.confirmResend(payload);
        setSeconds(0);
    };

    //***********************************************************************************
    // Check to see if the actions table for the user has changed, if so and they
    // no longer have the account-confirm action - load up Your Ready to Go component
    const checkVerificationStatus = async () => {
        // This is the only place we should call getPlayerData to manually get a new SPAT as
        // we are checking to see if a non-webapp action is changing the user's status.
        await actions.userActions.getPlayerData();
        if (user?.player?.actions?.length) {
            if (!user.player.actions.includes('account-confirm')) {
                setTimerActive(false);
                setAccountConfirmed(true);
            }
        }
    };

    //***********************************************************************************
    // On Load, set up timer and check validation status on regular basis (10 seconds).
    // if past timeout, direct user to call customer service.
    useEffect(() => {
        let interval = null;
        if (timerActive && seconds < timeout) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds + 10);
                checkVerificationStatus();
            }, 10000);
        } else if (!timerActive && seconds !== 0) {
            clearInterval(interval);
        } else if (seconds >= timeout) {
            setTimerActive(false);
            clearInterval(interval);
            setTimeoutHit(true);
        }
        return () => clearInterval(interval);
    }, [timerActive, seconds]);

    if (accountConfirmed) {
        return <ReadyToGo />;
    } else if (timeoutHit) {
        return <CantFindConfirmation />;
    } else {
        return (
            <div className='row justify-content-center'>
                <div className='col-12 col-md-10 col-lg-8'>
                    <ContentBox className='form-step' variant='theme-primary'>
                        <ContentBoxHead>Welcome!</ContentBoxHead>
                        <ContentBoxBody>
                            <div className='text-center'>
                                <CMSContent localStorageObject='webContent' contentPath='data.sitewideSettings.mainLogo.image' className='img-fluid'
                                            cmsSourceFirebase={cmsSourceFirebase} />
                            </div>
                            <TeleScript line={accountConfirmationTelescript?.accountCreatedNextSteps}>
                                <p className='text-center'>
                                    Your account has been created. Follow the link sent to <strong>{user.player.email}</strong> to confirm your registration
                                    and begin earning Rewards. Please allow up to 15 minutes to receive your email.
                                </p>
                                <p className='text-center'>
                                    Didn&apos;t receive your confirmation email?
                                </p>
                                <p className='text-center'>
                                    <Link to='#' onClick={resendConfirmationEmail}>
                                        <button type='button' className='resend-confirm-email-btn'>Resend Confirmation Email</button>
                                    </Link>
                                </p>
                                <p className='text-center'>
                                    Wrong email?
                                </p>
                                <p className='text-center'>
                                    <Link to='/feedback#contact'>
                                        <button type='button' className='edit-email-btn'>Edit Email Address</button>
                                    </Link>
                                </p>
                            </TeleScript>
                        </ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        );
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AwaitAccountConfirmation);
