import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Link } from 'react-router-dom';
import { ContentBox, ContentBoxBody, ContentBoxHead } from 'sg-ui-components';

/**********************************************************************
 * Component:  VerificationErrorTemplate
 * Purpose:    Simple Component that tells user that they need
 *             to call player services, as they cannot be verified via
 *             our system.
 *
 * Props:     none
 *
 * APIs used:   None
 *
 *  Notes:
 */
const VerificationErrorTemplate = () => {
    return (
        <ContentBox className='form-step step6'>
            <ContentBoxHead>Please Contact Player Services</ContentBoxHead>
            <ContentBoxBody>
                <div className='text-center'>
                    <img src='players-club-logo.png' className='img-fluid mx-auto my-3' />
                </div>
                <p className='text-center'>
                    Your account could not be verified and needs manual validation via our Player Services Department.
                    <br />
                    Please contact us at 1-800-201-0108.
                </p>
                <p className='text-center'>
                    <Link to='/feedback#contact' className='btn theme-btn btn-danger'>
                        Contact Player Services
                    </Link>
                </p>
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationErrorTemplate);
