import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';

import { SweepstakeDetails, getCMSObject, DrawingGroupTable } from 'sg-ui-components';

const SecondChancePromotion = ({ user, config, cmsSourceFirebase, actions, match, sweep, loading }) => {
    const telescriptSweepstakeDetails = getCMSObject('data.components.teleScripts.sweepstakeDetails.jsonBlock');
    const telescriptDrawingGroupTable = getCMSObject('data.components.teleScripts.drawingGroupTable.jsonBlock');
    const [promotionName, setPromotionName] = useState('Promotion Details');

    const DrawingGroupTableWrapper = ({ drawingGroup, loggedIn }) => {
        return <DrawingGroupTable drawingGroup={drawingGroup} loggedIn={loggedIn} telescript={telescriptDrawingGroupTable} WinnerLink={WinnerLinkWrapper} />;
    };

    return (
        <Layout
            {...{
                user,
                config,
                cmsSourceFirebase,
                actions,
                match,
                configReplacements: [
                    {
                        find: '{{PROMOTION_NAME}}',
                        replace: promotionName ?? '',
                    },
                ],
            }}>
            <SweepstakeDetails
                {...{ user, sweep: sweep.sweepDetails, actions, loading }}
                telescript={telescriptSweepstakeDetails}
                DrawingGroupTable={DrawingGroupTableWrapper}
                setPageTitle={setPromotionName}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondChancePromotion);
