import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import PasswordExpiring from '../Components/Login/PasswordExpiring';

const PasswordExpiringPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <PasswordExpiring />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiringPage);
