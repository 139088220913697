import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ContentBox, ContentBoxHead, ContentBoxBody, CMSContent, logError } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const PageNotFound = ({ user, config, cmsSourceFirebase, actions, match }) => {

    useEffect(() => {
        logError({
            errorCode: 'PAGE_NOT_FOUND',
            severity: 'error',
            category: 'generic',
            user,
            config,
        });
    }, []);

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <ContentBox variant='theme-red' show='true'>
                <ContentBoxHead>
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.sitewideSettings.pageNotFound.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                </ContentBoxHead>
                <ContentBoxBody>
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.sitewideSettings.pageNotFound.contentHTML'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                </ContentBoxBody>
            </ContentBox>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
