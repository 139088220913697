import { Link } from 'react-router-dom';
import { CMSContent, ContentBox, ContentBoxBody, ContentBoxHead, getCMSObject, TeleScript } from 'sg-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Store';

/**********************************************************************
 * Component:  CantFindConfirmation
 * Purpose:    Simple Component that tells user to call customer service
 *             if they can't find their confirmation email after the timeout
 *             period
 *
 * Props:      cmsSourceFirebase - reference to the Firebase CMS
 *
 * APIs used:   None
 */
export const CantFindConfirmationTemplate = ({ cmsSourceFirebase }) => {
    const cantFindConfirmationTeleScript = getCMSObject('data.components.teleScripts.cantFindConfirmation.contentHTML');

    return (
        <div className='row justify-content-center'>
            <div className='col-12 col-md-10 col-lg-8'>
                <ContentBox className='form-step' variant='theme-primary'>
                    <ContentBoxHead>Can&apos;t Find Your Confirmation Email?</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='text-center'>
                            <CMSContent localStorageObject='webContent' contentPath='data.sitewideSettings.mainLogo.image' className='img-fluid'
                                        cmsSourceFirebase={cmsSourceFirebase} />
                        </div>
                        <p className='text-center'>
                            <TeleScript line={cantFindConfirmationTeleScript}>
                                It seems like you can&apos;t find or did not receive your confirmation email. Fill our a help form and we will contact you to
                                verify.
                            </TeleScript>
                        </p>
                        <p className='text-center'>
                            <Link to='/feedback#contact'>
                                <button type='button' className='cant-find-confirmation-contact-btn'>
                                    Contact Us
                                </button>
                            </Link>
                        </p>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </div>
    );
};
const CantFindConfirmation = connect(mapStateToProps, mapDispatchToProps)(CantFindConfirmationTemplate);
export { CantFindConfirmation };