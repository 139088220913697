import React from 'react';
import AppStorePromo from '../../../assets/images/playersclub-icon.svg';
import AppleStore from '../../../assets/images/AppleStoreBanner.png';
import PlayStore from '../../../assets/images/PlayStoreBanner.png';
import { TeleScript, getCMSObject } from 'sg-ui-components';

const AppStoreColumn = () => {
    const appStoresDetails = getCMSObject('data.components.teleScripts.appStoresDetails.jsonBlock');

    return (
        <div className='text-center mb-2 p-2 app-store-text'>
            <img src={AppStorePromo} className='img-fluid mx-auto ' alt='Delaware Lottery Players Club Logo' />
            <TeleScript line={appStoresDetails?.instructions}>Download the Players&apos; Club App and scan tickets in a flash!</TeleScript>

            <div className='lg-col md-row'>
                <a href={`https://apps.apple.com/us/app/de-lottery-players-club/id6462989506`} aria-label='App Store Delaware Lottery'>
                    <img src={AppleStore} className='img-fluid mx-auto my-2 app-store-image ' alt='App Store Logo' />
                </a>
                <a href={`https://play.google.com/store/apps/details?id=com.delaware.lottery`} aria-label='Play Store Delaware Lottery'>
                    <img src={PlayStore} className='img-fluid mx-auto app-store-image   ' alt='Play Store Logo' />
                </a>
            </div>
        </div>
    );
};

export default AppStoreColumn;
