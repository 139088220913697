import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, ForgotPassword } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const ForgotPasswordPage = ({ user, config, loading, cmsSourceFirebase, actions, match }) => {
    const forgotPasswordTelescript = getCMSObject('data.components.teleScripts.forgotPassword.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <ForgotPassword user={user} loading={loading} actions={actions} telescript={forgotPasswordTelescript} />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
