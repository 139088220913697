import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { getCMSObject, TeleScript } from 'sg-ui-components';
import '../../../assets/fontawesome-6.3.0/css/fontawesome.css';
import '../../../assets/fontawesome-6.3.0/css/brands.css';

const Footer = ({ config }) => {
    const lotteryUrl = config?.config?.lottery_host ?? 'https://playersclub.delottery.com';
    return (
        <div className='page-footer'>
            <footer>
                <TeleScript
                    note='footer top nav'
                    line={getCMSObject('data.lotteryWrapper.wrapperFooter.wrapperFooterTopNav.contentHTMLCode')}
                    replace={{
                        '%LOTTERY_URL%': lotteryUrl,
                    }}
                />
            </footer>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
